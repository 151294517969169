<template>
  <div>
    <div class="home-header">
      <div class="home-header-location">
        <div class="location-logo">
          <img :src="locationLogo">
        </div>
        <div class="location-name">
          {{ $t('views.Home.headerText') }}
        </div>
      </div>
      <div class="home-header-language-selector">
        <language-selector />
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSelector from '@/components/LanguageSelector.vue'

export default {
  components: {
    LanguageSelector
  },
  computed: {
    locationLogo() {
      return process.env.VUE_APP_LOCATOR_LOGO
    }
  }
}
</script>

<style lang="scss" scoped>
.home-header {
  padding: $spacing-m;
  background-color: $color_light-gray;

  .home-header-location {
    display: inline-block;
    width: 65%;

    .location-logo {
      display: inline-block;
      vertical-align: middle;
      margin-right: $spacing-xs;
      // cursor: pointer;

      img {
        width: 23px;
        padding-top: $spacing-4xs;
      }
    }

    .location-name {
      @include text_subtitle-2;
      display: inline-block;
      vertical-align: middle;
      // cursor: pointer;
    }
  }

  .home-header-language-selector {
    display: inline-block;
    width: 35%;
    text-align: right;
  }
}
</style>
