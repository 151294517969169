<template>
  <div>
    <div class="select-wrapper">
      <select v-if="showPersonFilter" @change="changePersonFilter">
        <option
          v-for="person in personValues"
          :key="person.value"
          :value="person.value"
        >
          {{ person.value }} {{ personValuesLabel }}
        </option>
      </select>
    </div>
    <div class="select-wrapper">
      <select v-model="selectedDateTime.date">
        <option
          v-for="(date,idx) in dateValues"
          :key="idx"
          :value="date.value"
        >
          {{ date.label }}
        </option>
      </select>
    </div>
    <div class="select-wrapper">
      <select v-model="selectedDateTime.time">
        <option
          v-for="(time, idx) in timeValues"
          :key="idx"
          :value="time.value"
          :disabled="timeDisabled(time.value)"
        >
          {{ time.label }}
        </option>
      </select>
    </div>
    <div v-if="allIntegrations.length > 0" class="dropdown-check-list" :class="{'visible': appsVisible}" >
      <span class="anchor" @click="appsVisible = !appsVisible">
        {{$t('components.MapFilters.connectedApps')}}
      </span>
      <div class="items-container">
        <ul class="items">
          <li v-for="(i, idx) in allIntegrations" :key="idx" @click="toggleAppSelection(i)">
            <span>
              <input type="checkbox" :value="i" v-model="selectedIntegrations"/> 
              {{$t(`components.AppIntegrations.${i}`)}} 
            </span>
            <div 
              class="app-integration-icon"
              :style="{
                'background-image': `url(${getIntegrationIcon(i)}`
              }"
            ></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { EventBus } from '@/plugins/event-bus.js';
import moment from 'moment'

const DAYS_IN_ADVANCE=14
const MIN_HOUR=0
const MAX_HOUR=24
const ALL_DAY_DEFAULT=true

export default {
  props: {
    showPersonFilter: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      personValues: [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 }
      ],
      dateValues: [],
      appsVisible: false,
      selectedDateTime: null,
      selectedIntegrations: []
    }
  },
  computed: {
    allIntegrations() {
      let ai = []
      let integrationString = process.env.VUE_APP_INTEGRATIONS
      
      if (integrationString && integrationString.length > 0) {
        ai = process.env.VUE_APP_INTEGRATIONS.split(',')
      }

      return ai
    },
    personValuesLabel() {
      return this.$t('components.MapFilters.person')
    },
    timeValues() {
      const timeValues = []
      timeValues.push({label: this.$t('components.MapFilters.all-day'), value: null})
      for (let i=MIN_HOUR; i<MAX_HOUR; i++) {
        timeValues.push({label: `${i}:00-${i+1}:00`, value: i});
      }
      return timeValues
    }
  },
  beforeMount(){
    this.generateDays();
    this.setCurrentDateTime();
  },
  watch: {
    selectedDateTime: {
      deep: true,
      handler(valToEmit) {
        EventBus.$emit('date-time-filter-changed', valToEmit)
      }
    },
    selectedIntegrations(valToEmit){
      let filter = this.selectedDateTime
      filter["integrations"] = valToEmit
      EventBus.$emit('date-time-filter-changed', filter)
    }
  },
  methods: {
    toggleAppSelection(app){
      let idx = this.selectedIntegrations.indexOf(app)
      if (idx >= 0) {
        this.selectedIntegrations.splice(idx,1)
      } else {
        this.selectedIntegrations.push(app)
      }
    },
    generateDays() {
      for (let i=0; i<DAYS_IN_ADVANCE; i++) {
        let date = new DateTime.local().plus({days: i});
        this.dateValues.push({
          value: {year: date.year, month: date.month, day: date.day },
          label: moment(date.toISODate()).format('DD-MM-yyy')
        })
      }
    },
    setCurrentDateTime() {
      let now = new DateTime.local();
      let date = {year: now.year, month: now.month, day: now.day}
      let time = now.hour

      if (ALL_DAY_DEFAULT) {
        time = null
      } else if (time >= MAX_HOUR) {
        now.setDate(now.getDate()+1)
        date = JSON.parse(JSON.stringify(now)).slice(0,10)
        time = MIN_HOUR
      } else if (time <= MIN_HOUR) {
        time = MIN_HOUR
      }

      this.selectedDateTime = {date: date, time: time}
    },
    timeDisabled(time) {
      // timeRange can be 00:00 - 01:00
      if (time === null) return false;
      let now = new DateTime.local();
      if (now.day == this.selectedDateTime.date.day) {
        return time < now.hour
      } else {
        return false
      }
    },
    changePersonFilter(e) {
      EventBus.$emit('person-filter-changed', e.target.value)
    },
    getIntegrationIcon(key) {
      if (key == 'cwa') {
        return require ('@/assets/icons/cwa_app_icon.png')
      } else if (key == 'luca') {
        return require ('@/assets/icons/luca_app_icon.png')
      } else if (key == 'dcc') {
        return require ('@/assets/icons/dcc_icon.png')
      } else {
        return null;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #DEE2E6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px 18px 4px 4px;
  background-color: $color_white;
  margin-right: $spacing-xxs;
  outline: none;
  font-family: Arial;
  font-size: 12px;
}

.select-wrapper {
  position: relative;
  display: inline;
}

.select-wrapper:after {
  position: absolute;
  content: "";
  border-left: 3px solid black;
  border-top: 3px solid black;
  padding: 4px;
  right: 10px;
  top: 20%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg) scale(0.7);
}


.dropdown-check-list {
  display: inline-block;
  position: relative;
  margin-top: 6px;
}

.dropdown-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  padding: 4px 50px 4px 10px;
  border: 1px solid #DEE2E6;
  border-radius: 4px;
  background-color: white;
  font-family: Arial;
  font-size: 12px;
}

 .dropdown-check-list.visible .anchor {
   border-radius: 4px 4px 0 0 !important;
 }

.dropdown-check-list .anchor:after {
  position: absolute;
  content: "";
  border-left: 3px solid black;
  border-top: 3px solid black;
  padding: 4px;
  right: 10px;
  top: 20%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg) scale(0.7);
}

.dropdown-check-list .items-container {
  position: absolute;
  background-color: white;
  width: 100%;
  border-radius: 0 0 4px 4px;
}
.dropdown-check-list ul.items {
  padding: 0;
  display: none;
  margin: 0;
  border: 1px solid #DEE2E6;
  background-color: white;
  border-top: none;
  border-radius: 0 0 4px 4px;
}

.dropdown-check-list ul.items li {
  padding-top: 6px;
  padding-bottom: 6px;
  list-style: none;
  font-size: 12px;
  font-family: Arial;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}



.dropdown-check-list.visible .items {
  display: block;
}

.app-integration-icon {
  margin-top: 3px;
  margin-right: $spacing-4xs;
  width: 16px;
  height: 16px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
