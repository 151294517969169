import { i18n } from "@/plugins/i18n"
import { Trans } from './plugins/translation'
import ClickOutside from '@/directives/clickOutside'
import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import VueCookies from 'vue-cookies';

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)

Vue.config.productionTip = false

Vue.use(VueCookies);

Vue.directive('click-outside', ClickOutside)

Vue.prototype.$gtag = window.gtag

new Vue({
  el: '#app',
  router,
  i18n,
  render: h => h(App)
})
