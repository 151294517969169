<template>
  <div
    class="location-item"
    :class="{ 'location-item__is-active': locationItem.isActive }"
    @click="locationItemClicked"
  >
    <div
      class="location-item-logo"
      :style="{
        'background-image': `url(${getLogo}), url(${getDefaultLogo})`
      }"
    ></div>
    <div class="location-item-name-wrapper">
      <div class="location-item-name">
        {{ locationItem.name }}
      </div>
      <div class="location-item-address">
        <span
          class="location-item-address-name"
          :class="{
            'location-item-address-name__fixed-width': isWidget
          }"
        >
          {{ locationItem.address }}, {{ locationItem.city }}
        </span>
        <span v-if="recentLocationDistance" class="location-item-address-distance">
          &#8226; {{ recentLocationDistance }}
        </span>
      </div>
      <div v-if="allIntegrations.length > 0" class="location-item-integrations">
        <div v-if="locationItem.appIntegrations.includes('cwa')"
          class="app-integration-icon"
          :style="{
            'background-image': `url(${require ('@/assets/icons/cwa_app_icon.png')}`
          }"
        ></div>
        <div v-if="locationItem.appIntegrations.includes('dcc')"
          class="app-integration-icon"
          :style="{
            'background-image': `url(${require ('@/assets/icons/dcc_icon.png')}`
          }"
        ></div>
        <div v-if="locationItem.appIntegrations.includes('luca')"
          class="app-integration-icon"
          :style="{
            'background-image': `url(${require ('@/assets/icons/luca_app_icon.png')}`
          }"
        ></div>
      </div>
    </div>
    <div class="location-item-btn-wrapper" v-if="!isWidget">
      <a
        href="#"
        @click="navToBooking(bookingLink(locationItem.slug, dateTimeFilter.date))"
        class="noq-button noq-button__primary noq-button__sm"
        :class="{ 'noq-button__disabled': locationItem.free <= 0 }"
        @click.stop
      >
        <template v-if="locationItem.free > 0">
          {{ locationItem.free }} {{ $t('components.LocationItem.places') }}
        </template>
        <template v-else-if="locationItem.booketOut">
          {{ $t('components.LocationItem.booketOut') }}
        </template>
        <template v-else>
          {{ $t('components.LocationItem.notAvailable') }}
        </template>
      </a>
    </div>
    <div class="location-item-values-wrapper" v-else>
      <div class="location-item-values-free">
        {{ locationItem.free }}
      </div>
      <div class="location-item-values-label">
        free
      </div>
    </div>
  </div>
</template>

<script>
import urlsMixin from '@/mixins/url_mixin'

export default {
  mixins: [urlsMixin],
  props: {
    dateTimeFilter: {
      type: Object,
      required: true
    },
    locationItem: {
      type: Object,
      required: true
    },
    recentLocation: {
      type: Object,
      required: false,
      default() {
        return null
      }
    },
    isWidget: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    getLogo() {
      return `${process.env.VUE_APP_LOGO_BASE_URL}${this.locationItem.slug}.jpg`
    },
    getDefaultLogo() {
      return require('@/assets/images/no-q_no-pharma-logo.svg')
    },
    recentLocationDistance() {
      if (!!this.recentLocation && 'distance' in this.locationItem) {
        return `${this.locationItem.distance}km`
      } else {
        return this.getDistanceFromLatLonInKm
      }
    },
    // https://stackoverflow.com/a/27943
    getDistanceFromLatLonInKm() {
      const lat1 = this.locationItem.lat
      const lon1 = this.locationItem.lng
      let lat2
      let lon2

      if (!this.recentLocation) {
        return false
      } else if (!!this.recentLocation && 'lat' in this.recentLocation && 'lng' in this.recentLocation) {
        lat2 = this.recentLocation.lat
        lon2 = this.recentLocation.lng
      } else {
        lat2 = this.recentLocation.geometry.location.lat()
        lon2 = this.recentLocation.geometry.location.lng()
      }

      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
      var dLon = this.deg2rad(lon2-lon1); 
      var a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ; 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c; // Distance in km
      d = Number((d).toFixed(2))
      return `${d} km`;
    },
    allIntegrations() {
      let ai = []
      let integrationString = process.env.VUE_APP_INTEGRATIONS
      
      if (integrationString && integrationString.length > 0) {
        ai = process.env.VUE_APP_INTEGRATIONS.split(",")
      }

      return ai
    },
  },
  methods: {
    navToBooking(url) {
      window.open(url, '_blank');
      const event_id = process.env.VUE_APP_GTAG_NAV_TO_NOQ_EVENT_ID
      if (this.$gtag && event_id) {
        this.$gtag('event', 'conversion', {
          'send_to': event_id,
        });
      }
    },
    // https://stackoverflow.com/a/27943
    deg2rad(deg) {
      return deg * (Math.PI/180)
    },
    locationItemClicked() {
      this.$emit('location-click', this.locationItem)
    }
  }
}
</script>

<style lang="scss">
.location-item {
  padding: $spacing-m $spacing-s $spacing-m $spacing-xs;
  border-bottom: 1px solid rgba(222, 226, 230, 0.4);
  display: flex;
  justify-content: space-around;
  align-items: center;

  &:last-child {
    border-bottom: 0;
  }

  &:hover,
  &__is-active {
    background-color: $color_dark-gray;
    cursor: pointer;
  }

  @media (max-width: $breakpoint-tablet) {
    background-color: unset;
  }

  .location-item-logo {
    float: left;
    margin-right: $spacing-s;
    width: 32px;
    height: 32px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .app-integration-icon {
    display: inline-block;
    margin-right: $spacing-3xs;
    width: 18px;
    height: 18px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .location-item-name-wrapper {
    float: left;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 300px;

    @media (max-width: $breakpoint-tablet) {
      width: 60%;
      max-width: unset;
    }

    @media (max-width: $breakpoint-mobile-m) {
      width: 55%;
      max-width: unset;
    }

    @media (max-width: $breakpoint-mobile-s) {
      width: 49%;
      max-width: unset;
    }

    .location-item-name {
      @include text_lg;
      margin-bottom: $spacing-3xs;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .location-item-address {
      @include text_sm;
      color: $color_gray;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      .location-item-address-name {
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &__fixed-width {
          max-width: 140px;
        }
      }

      .location-item-address-distance {
        display: inline-block;
        vertical-align: top;
        color: $color_black;
        padding-left: $spacing-4xs;
      }

    }
  }

  .location-item-btn-wrapper {
    margin-left: $spacing-s;
  }

  .location-item-values-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-left: $spacing-l;

    .location-item-values-free {
      @include text_lg;
      margin-bottom: $spacing-3xs;
    }

    .location-item-values-label {
      @include text_sm;
      color: $color_gray;
    }
  }
}
</style>
