<template>
  <div>
    <div class="home-body">
      <h1>
        {{ $t('views.Home.title') }}
      </h1>
      <div class="home-body-descr">
        {{ $t('views.Home.descr1') }}
      </div>
      <div v-if="showImage" class="image-map-container">
        <div class="image-map-wrapper">
          <img :src="bodyMapImage" alt="">
        </div>
        <div class="image-map-button-wrapper">
          <router-link :to="{ name: 'pharmaMap' }" class="noq-button">
            {{ $t('views.Home.findLocation') }}
          </router-link>
        </div>
      </div>
      <h4 v-if="false">
        {{ $t('views.Home.subtitle1') }}
      </h4>
      <div class="home-body-descr">
        <p v-html="$t('views.Home.descr2')"></p>
        <p v-html="$t('views.Home.descr3')"></p>
        <p v-html="$t('views.Home.descr4')"></p>
        <p v-html="$t('views.Home.descr5')"></p>
        <p v-html="$t('views.Home.descr6')"></p>
      </div>
    </div>
    <!-- <div class="home-footer">
      <a href="https://www.no-q.info" target="_blank">
        {{ $t('views.Home.serviceProvidedByNoQ') }}
      </a>
      &nbsp;•&nbsp;
      <a href="https://www.no-q.info/impressum/" target="_blank">
        {{ $t('views.Home.imprint') }}
      </a>
      &nbsp;•&nbsp;
      <a href="https://www.no-q.info/datenschutz/" target="_blank">
        {{ $t('views.Home.privacy') }}
      </a>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    showImage: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    bodyMapImage() {
      return require('@/assets/images/no-q_home_image_map.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.home-body {
  padding: 0 $spacing-m;

  h1 {
    color: $color_black;
    margin-bottom: $spacing-l;
  }

  h4 {
    color: $color_black;
  }

  .home-body-descr {
    @include text_lg;
    color: $color_gray;
  }

  .image-map-container {
    position: relative;
    height: 100%;
    display: block;
    width: 100%;

    @media (max-width: $breakpoint-tablet) {
      width: 100vw;
      margin-left: calc(50% - 50vw);
    }

    .image-map-button-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 40px;
      text-align: center;

      .noq-button {
        display: inline-block;
        width: 230px;
      }
    }
  
    .image-map-wrapper {
      margin: $spacing-3xl 0;
      width: 100%;
      position: relative;

      @media (max-width: $breakpoint-tablet) {
        width: 100vw;
      }

      img {
        width: 100%;
      }
    }
  }
}

// .home-footer {
//   @include text_body;
//   padding: 22px 0 19px 0;
//   text-align: center;
//   margin: 0 auto;
//   width: 100%;

//   height: 21px;

//   a {
//     text-decoration: none;
//     color: unset;
//   }
// }
</style>